export const routes = {
  landing: {
    base: {
      path: '/',
    },
    category: {
      voice: {
        path: '/mobilabonnement',
      },
      data: {
        path: '/mobiltbredband',
      },
      iot: {
        path: '/iot',
      },
    },
    contact: {
      path: '/contact',
    },
    usecase: {
      path: '/usecase/:category/:id',
    },
  },
  overview: {
    path: '/overview',
  },
  subscriptions: {
    user: {
      path: '/subscriptions/personal',
    },
    company: {
      path: '/subscriptions/company',
    },
    pending: {
      path: '/subscriptions/orders',
    },
  },
  order: {
    basketSummary: {
      path: '/order/basket',
    },
    checkout: {
      path: '/order/checkout',
    },
    shipping: {
      path: '/order/shipping',
    },
    summary: {
      path: '/order/summary',
    },
  },
  orders: {
    path: '/orders',
  },
  activate: {
    path: '/activate',
  },
  products: {
    overview: {
      path: '/products',
    },
    category: {
      voice: {
        path: '/products/voice',
      },
      data: {
        path: '/products/data',
      },
      iot: {
        path: '/products/iot',
      },
    },
  },
  callRates: {
    domesticNumberPlans: {
      path: '/call-rates/domestic',
    },
    roam: {
      path: '/call-rates/roam',
    },
  },
  admin: {
    userManagement: {
      path: '/admin/users',
    },
  },
  recommendations: {
    path: '/recommendations',
  },
  terms: {
    path: '/terms',
  },
  iot: {
    path: '/mobilnett',
  },
  coverage: {
    path: '/coverage',
  },
};
