import { z } from 'zod';

export const userRoles = ['Admin', 'Approver', 'User'] as const;

const userSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  userPrincipalName: z.string(),
  role: z.enum(userRoles),
});

export const usersSchema = z.object({
  items: z.array(userSchema),
});

export type UserDto = z.infer<typeof userSchema>;
export type UsersDto = z.infer<typeof usersSchema>;
export type UserRole = (typeof userRoles)[number];
