export enum QueryKey {
  HistoricalCompanyConsumption = 'HistoricalCompanyConsumption',
  Recommendations = 'Recommendations',
  Users = 'Users',
  TermsAgreementStatus = 'TermsAgreementStatus',
  ApnNetworkConfigs = 'ApnNetworkConfigs',
  ProfilePicture = 'ProfilePicture',
  Articles = 'Articles',
  MyUser = 'MyUser',
  MSGraph = 'MSGraph',
  HistoricalConsumption = 'HistoricalConsumption',
  SIM = 'SIM',
  DatalimitNotificationSetting = 'DatalimitNotificationSetting',
  SubscriptionsDistribution = 'SubscriptionsDistribution',
  BusinessSubscriptions = 'BusinessSubscriptions',
  RotUsers = 'RotUsers',
  CurrentMonthDataUsage = 'CurrentMonthDataUsage',
  SubscriptionOrders = 'SubscriptionOrders',
  DataPlans = 'DataPlans',
  Locations = 'Locations',
  SimAvailability = 'SimAvailability',
  BillingCompanies = 'BillingCompanies',
  EsimStatus = 'EsimStatus',
  EsimActivationProfile = 'EsimActivationProfile',
  SubscriptionDataPlanHistory = 'SubscriptionDataPlanHistory',
  RoamingPrices = 'RoamingPrices',
  OrderEsim = 'OrderEsim',
  SubscriptionServices = 'SubscriptionServices',
}
