import { API } from '@/auth/config';
import { api } from '@/auth/fetch';
import { rotUsersSchema } from './getRotUsers.schema';

export const getRotUsers = async (name: string | undefined) => {
  if (!name) return { items: [] };

  const endpoint = `${API.Sim.url}/v1/users`;
  const data = await api.get(endpoint, { searchParams: { name: name } }).json();

  return rotUsersSchema.parse(data);
};

// export const useRotUsers = (name: string | undefined) => {
//   return useQuery({
//     queryKey: [QueryKey.RotUsers],
//     queryFn: () => getRotUsers(name),
//     enabled: !!name?.length && name.length >= 3, // only fetch if 3 or more characters have been entered
//   });
// };
