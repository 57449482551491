import { z } from 'zod';

const rotUserSchema = z.object({
  AzureObjectId: z.string(),
  FullName: z.string(),
  Mobile: z.string(),
  Address: z.object({
    StreetName: z.string(),
    StreetNumber: z.string(),
    City: z.string(),
    ZipCode: z.string(),
  }),
  ADUser: z.object({
    userPrincipalName: z.string(),
  }),
});

export const rotUsersSchema = z.object({
  items: z.array(rotUserSchema),
});

export type RotUserDto = z.infer<typeof rotUserSchema>;
export type RotUsersDto = z.infer<typeof rotUsersSchema>;
