import { SubscriptionCategoryType } from '@/features/subscriptions/api';
import { type DataPlanDto } from '../api/getDataPlans.schema';
import type { SubscriptionProductType } from '../types/products';

export const categorizeDataPlans = (dataPlans: DataPlanDto[] | undefined) => {
  const categorizedDataPlans: Record<SubscriptionProductType, DataPlanDto[]> = {
    VOICE: [],
    DATA: [],
    IOT: [],
  };

  for (const dataPlan of dataPlans ?? []) {
    if (dataPlan.subscriptionCategoryType === SubscriptionCategoryType.VOICE) {
      categorizedDataPlans.VOICE.push(dataPlan);
    } else if (dataPlan.subscriptionCategoryType === SubscriptionCategoryType.DATA) {
      categorizedDataPlans.DATA.push(dataPlan);
    } else if (dataPlan.subscriptionCategoryType === SubscriptionCategoryType.IOT) {
      categorizedDataPlans.IOT.push(dataPlan);
    }
  }

  return categorizedDataPlans;
};
