import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Button, Grid, Table, useBreakpoint } from '@intility/bifrost-react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Counter } from '@/components/Counter';
import { ModalContext } from '@/context/ModalContext';
import { routes } from '@/routes/config';
import { formatPrice } from '@/utils/format';
import { type DataPlanDto } from '../api/getDataPlans.schema';
import { useUpdateBasket } from '../hooks/useUpdateBasket';

interface BasketModalProps {
  product: DataPlanDto;
}

export const BasketModal = ({ product }: BasketModalProps) => {
  const { handleModal } = useContext(ModalContext);
  const isSmallScreen = useBreakpoint(null, 'small');

  const { handleOnCounterInputChange, currentBasketQuantity } = useUpdateBasket(
    product,
    handleModal,
  );

  return (
    <Grid>
      <Table noBorder>
        {isSmallScreen ? (
          <Table.Body>
            <Table.Row>
              <Table.HeaderCell>Navn</Table.HeaderCell>
              <Table.Cell>{product.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Antall lagt til</Table.HeaderCell>
              <Table.Cell>
                <Counter onInputChange={handleOnCounterInputChange} count={currentBasketQuantity} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Pris per mnd</Table.HeaderCell>
              <Table.Cell>{formatPrice(product.customerMonthlyPrice)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Totalt per mnd</Table.HeaderCell>
              <Table.Cell>
                {formatPrice(currentBasketQuantity * product.customerMonthlyPrice)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Abonnement</Table.HeaderCell>
                <Table.HeaderCell>Antall lagt til</Table.HeaderCell>
                <Table.HeaderCell>Pris per mnd</Table.HeaderCell>
                <Table.HeaderCell>Totalt per mnd</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{product.name}</Table.Cell>
                <Table.Cell>
                  <Counter
                    onInputChange={handleOnCounterInputChange}
                    count={currentBasketQuantity}
                  />
                </Table.Cell>
                <Table.Cell>{formatPrice(product.customerMonthlyPrice)}</Table.Cell>
                <Table.Cell>
                  {formatPrice(currentBasketQuantity * product.customerMonthlyPrice)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </>
        )}
      </Table>

      <div>
        <Button onClick={() => handleModal()}>Bestill flere</Button>

        <Link to={routes.order.basketSummary.path}>
          <Button
            rightIcon
            icon={faArrowRight}
            onClick={() => handleModal()}
            variant='filled'
            className='float-right'
          >
            Til oppsummering
          </Button>
        </Link>
      </div>
    </Grid>
  );
};
