import { AsyncSelect } from '@intility/bifrost-react-select';
import { forwardRef } from 'react';
import type { SelectInstance } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';

import { getRotUsers } from '@/api/getRotUsers';
import { type RotUserDto } from '@/api/getRotUsers.schema';

export type User = {
  azureObjectId: string;
  fullName: string;
  userPrincipalName: string;
};

interface UserSelectProps {
  value: User | null;
  onChange: (user: User | null) => void;
  feedback?: string;
  state?: 'default' | 'alert' | undefined;
  description?: string;
  onBlur?: () => void;
}

const userDtoToUser = (user: RotUserDto): User => ({
  azureObjectId: user.AzureObjectId,
  fullName: user.FullName,
  userPrincipalName: user.ADUser.userPrincipalName,
});

export const UserSelect = forwardRef<SelectInstance<User>, UserSelectProps>(
  ({ value, onChange, feedback, state, description, onBlur }: UserSelectProps, ref) => {
    const debouncedUsers = useDebouncedCallback(
      (nameInput: string, callback: (options: User[]) => void) => {
        getRotUsers(nameInput)
          .then(users => callback(users.items.map(userDtoToUser)))
          .catch(() => callback([]));
      },
      500,
      { leading: false, trailing: true },
    );

    return (
      <AsyncSelect
        ref={ref}
        label='Bruker'
        placeholder='Søk...'
        getOptionLabel={(user: User) => user.fullName}
        getOptionValue={(user: User) => user.fullName}
        onChange={(user, { action }) => onChange(action === 'clear' ? null : user)}
        loadOptions={debouncedUsers}
        loadingMessage={() => 'Søker...'}
        defaultOptions={false}
        cacheOptions
        value={value}
        description={description}
        isClearable
        isSearchable
        feedback={feedback}
        onBlur={onBlur}
        state={state}
        noOptionsMessage={input => (input.inputValue ? 'Ingen resultater' : 'Søk')}
      />
    );
  },
);

UserSelect.displayName = 'UserSelect';
