import { z } from 'zod';

export const SubscriptionCategoryType = {
  VOICE: 'VOICE',
  DATA: 'DATA',
  IOT: 'IOT',
  COMLINE: 'COMLINE',
} as const;

export const Carrier = {
  TELIA: 'Telia',
  TELENOR: 'Telenor',
} as const;

export type Carrier = (typeof Carrier)[keyof typeof Carrier];

export const SimType = {
  ESIM_NO_PIN: 'ESIM_NO_PIN',
  ESIM_PIN: 'ESIM_PIN',
  REGULAR_NO_PIN: 'REGULAR_NO_PIN',
  REGULAR_PIN: 'REGULAR_PIN',
  IOT_NO_PIN: 'IOT_NO_PIN',
  IOT_PIN: 'IOT_PIN',
  // Test SIMs
  TEST_ESIM_NO_PIN: 'TEST_ESIM_NO_PIN',
  TEST_ESIM_PIN: 'TEST_ESIM_PIN',
  TEST_REGULAR_NO_PIN: 'TEST_REGULAR_NO_PIN',
  TEST_REGULAR_PIN: 'TEST_REGULAR_PIN',
  TEST_IOT_NO_PIN: 'TEST_IOT_NO_PIN',
  TEST_IOT_PIN: 'TEST_IOT_PIN',
} as const;

export type SimType = (typeof SimType)[keyof typeof SimType];

const simSchema = z.object({
  simId: z.string(),
  simType: z.nativeEnum(SimType),
  createdAt: z.string(),
  carrier: z.nativeEnum(Carrier),
  simStatus: z.enum(['AVAILABLE', 'IN_USE', 'AGING', 'NOT_ASSIGNABLE', 'RESERVED']),
});

export const businessSubscriptionSchema = z.object({
  subscriptionId: z.string(),
  createdAt: z.string(),
  description: z.string().nullable(),
  isFavorite: z.boolean(),
  rolloverMegabyteData: z.number(),
  isTerminated: z.boolean(),
  carrier: z.nativeEnum(Carrier),
  phoneNumber: z.object({
    phoneNumberId: z.string(),
    phoneNumber: z.string(),
    countryCode: z.number(),
    originalServiceProviderId: z.string(),
    previousServiceProviderId: z.string(),
    isSecretNumber: z.boolean(),
    isPortedIn: z.boolean(),
    phoneNumberType: z.enum(['VOICE', 'DATA']),
  }),
  attributes: z.object({
    companyCode: z.string(),
    companyId: z.string(),
    companyName: z.string().nullable(),
    locationCode: z.string(),
    locationId: z.string(),
    locationName: z.string().nullable(),
    attachedUserEntraObjectId: z.string(),
    attachedUserEmail: z.string().nullable(),
    attachedUserFullName: z.string().nullable(),
    billingCompanyId: z.number(),
    billingCompanyName: z.string().nullable(),
    tenantId: z.string(),
    organizationNumber: z.string(),
  }),
  subscriptionCategoryType: z.nativeEnum(SubscriptionCategoryType),
  activeDataPlan: z.object({
    dataPlanId: z.string(),
    name: z.string(),
    fullName: z.string(),
    description: z.string().nullable(),
    carrier: z.nativeEnum(Carrier),
    includedByteDataLimit: z.number(),
    underlyingByteDataLimit: z.number().nullable(),
    customerMonthlyPrice: z.number(),
    overUsageGigabytePrice: z.number(),
    setupPrice: z.number(),
    isDisabled: z.boolean(),
    isFavorite: z.boolean(),
    subscriptionType: z.object({
      code: z.string(),
      name: z.string(),
    }),
    subscriptionCategoryType: z.enum(['COMLINE', 'DATA', 'IOT', 'VOICE']),
    apnNetworkConfig: z.string(),
    tenantId: z.string().nullable(),
    currency: z.string(),
  }),
  primarySim: simSchema,
  additionalSims: z.array(simSchema),
});

export const businessSubscriptionsSchema = z.object({
  totalItems: z.number(),
  items: z.array(businessSubscriptionSchema),
});

export type BusinessSubscriptionDto = z.infer<typeof businessSubscriptionSchema>;
export type BusinessSubscriptionsDto = z.infer<typeof businessSubscriptionsSchema>;
export type SubscriptionCategoryType =
  (typeof SubscriptionCategoryType)[keyof typeof SubscriptionCategoryType];

export type SubscriptionSimDto = z.infer<typeof simSchema>;

export type PhoneNumberDto = BusinessSubscriptionDto['phoneNumber'];
