import {
  faChartNetwork,
  faLaptopMobile,
  faMobileScreenButton,
} from '@fortawesome/pro-light-svg-icons';

import { SubscriptionCategoryType } from '@/features/subscriptions/api';
import type { CategoryMetadata, SubscriptionProductType } from '../types/products';

export const categoryMetadata: Record<SubscriptionProductType, CategoryMetadata> = {
  VOICE: {
    id: SubscriptionCategoryType.VOICE,
    categoryHeader: 'Mobilabonnement',
    categoryIngress: 'Et fullverdig bedriftsabonnement ferdig integrert med din IT-plattform',
    sellingPoints: [
      'Overvåket av Intility sikkerhets- og compliance-plattform 24/7/365',
      'Beskyttelse mot virus, malware, DDOS og sårbarheter',
      'Fri bruk av tale og SMS, data rollover, roam like home i EU/EØS & eSIM',
      'Valgfri datapakke',
    ],
    buttonText: 'Til mobilabonnement',
    icon: faMobileScreenButton,
  },
  DATA: {
    id: SubscriptionCategoryType.DATA,
    categoryHeader: 'Mobilt bredbånd',
    categoryIngress: 'Rent dataabonnement tilrettelagt for arbeid på reise eller fra hytta',
    sellingPoints: [
      'Overvåket av Intility sikkerhets- og compliance-plattform 24/7/365',
      'Beskyttelse mot virus, malware, DDOS og sårbarheter',
      'Data rollover, roam like home i EU/EØS & eSIM',
      'Valgfri datapakke',
    ],
    buttonText: 'Til mobilt bredbånd',
    icon: faLaptopMobile,
  },
  IOT: {
    id: SubscriptionCategoryType.IOT,
    categoryHeader: 'IoT abonnement',
    categoryIngress: 'Abonnement tilpasset for sensorer og andre IoT-enheter',
    sellingPoints: [
      'Overvåket av Intility sikkerhets- og compliance-plattform 24/7/365',
      'Beskyttelse mot virus, malware, DDOS og sårbarheter',
      'Skreddersydd nettverksoppsett med statisk/dynamisk IP-adresse',
      'NB-IoT dekning',
    ],
    buttonText: 'Til IoT abonnement',
    icon: faChartNetwork,
  },
};
