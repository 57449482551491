import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Button, Grid, Icon } from '@intility/bifrost-react';
import { useContext } from 'react';

import { ModalContext } from '@/context/ModalContext';
import { useBasketActions } from '@/stores/basket';
import { type DataPlanDto } from '../api/getDataPlans.schema';
import { BasketModal } from './BasketModal';

interface ProductRowProps {
  product: DataPlanDto;
}

export const ProductRow = ({ product }: ProductRowProps) => {
  const { handleModal } = useContext(ModalContext);
  const { addToBasket } = useBasketActions();

  const addProduct = () => {
    addToBasket(product);
    handleModal(<BasketModal product={product} />, 'Abonnement lagt til', faCheckCircle);
  };

  const sellingPoints = product.sellingPoints;
  const isCompanyDeal = Boolean(product.tenantId);

  return (
    <Grid
      small={2}
      className='gap-bfs-24 border border-bfc-base-c-dimmed bg-bfc-base-3 p-bfs-24 first:rounded-t-lg last:rounded-b-lg'
      data-testid='product-row'
    >
      <div className='flex flex-col justify-center'>
        <h4 className='text-xl font-medium'>{product.name}</h4>

        {!!sellingPoints.length && (
          <ul className='mt-bfs-4 flex flex-col'>
            {sellingPoints.map((sellingPoint, i) => (
              <li className='mx-0 my-bfs-4 flex list-none flex-nowrap' key={i}>
                <Icon
                  className='mr-bfs-8 w-bfs-12 self-center'
                  icon={faCheck}
                  color='var(--bfc-theme)'
                />
                <p className='bf-p bf-small !m-0'>{sellingPoint.text}</p>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className='flex items-center gap-bfs-16 place-self-end self-center'>
        <div className='flex flex-col items-center gap-bfs-4'>
          <p className='bf-strong text-sm'>{product.customerMonthlyPrice}kr/mnd</p>
          {isCompanyDeal && <Badge pill>Firmaavtale</Badge>}
        </div>

        <Button variant='outline' onClick={addProduct}>
          <Icon icon={faPlus} marginRight />
          Legg til
        </Button>
      </div>
    </Grid>
  );
};
